import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook,
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/cvprovietnam/',
    tooltip: 'Facebook',
  }
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>CVPro là gì?</h2>
        <p>
          CVPro là công cụ giúp tạo ra CV chuyên nghiệp với hàng trăm mẫu được xây dựng sẵn hoàn toàn miễn phí và dễ dàng.
        </p>
      </AboutPageTitle>

      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>

      <AboutDetails>
        <h2>CVPro là gì?</h2>
        <p>
          CVPro là công cụ giúp tạo ra CV chuyên nghiệp với hàng trăm mẫu được xây dựng sẵn hoàn toàn miễn phí và dễ dàng.
        </p>
        <p>
          CVPro là công cụ giúp tạo ra CV chuyên nghiệp với hàng trăm mẫu được xây dựng sẵn hoàn toàn miễn phí và dễ dàng.
        </p>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
